import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import zhHant from 'vuetify/es5/locale/zh-Hant'
import colors from 'vuetify/lib/util/colors'
// import colors from './colors'
Vue.use(Vuetify)

export default new Vuetify({
  // lang: {
  //   locales: { zhHant },
  //   current: 'zh-Hant'
  // },
  theme: {
    // dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0072F7',
        secondary: '#00bcde',
        accent: '#082e62',
        error: '#f03746',
        success: '#00b778',
        'ems-aquamarine': '#00ddcb',
        'ems-blue': '#0092fd',
        'ems-powder-blue': '#c3dbfd',
        'ems-error': '#f83506',
        'ems-accent-secondary': '#164d6e',
        'ems-pale-blue': '#cae3f3',
        'ems-pale-grey': colors.grey.lighten3,
        'ems-light': '#ecf0f5',
        'ems-header': '#3080e8',
        'ems-blue-grey': '#729fc1',
        'ems-blue-grey-2': '#7185a3',
        'ems-bluegrey': '#889bb7',
        'ems-light-periwinkle': '#d6e1f2',
        'ems-light-grey-blue': '#a1b8d9',
        'ems-blue-grey-two': '#7185a3',
        'ems-light-grey': '#d6e1f1',
        line: '#07b53b'
      },
      dark: {
        primary: colors.blue.lighten3,
        'ems-pale-grey': colors.grey.lighten3,
        'ems-light': '#ecf0f5'
      }
    }
  }
})
