import Tag from '@/api/ems/Tag'
import IPC from '@/api/ems/IPC'
import router from '@/router'
import settings from '@/settings'

const state = {
  isAPPView: false,
  tags: null,
  navs: null,
  pageData: null,
  originTag: null,
  roleLevel: {
    CITY_ADMIN: 1,
    SCH_ADMIN: 2,
    SCH_AFFAIRS: 2,
    SCH_USER: 2,
    CLASS_USER: 3
  },
  navList: [
    {
      url: '/city',
      icon: '' // city
    },
    {
      url: '/dist',
      icon: 'mdi-map-marker' // district
    },
    {
      url: '/school',
      icon: 'mdi-home' // school
    },
    {
      url: '/class',
      icon: 'mdi-door' // class
    }
  ],
  ipcStatus: true,
  overlayLoading: false,
  isIlan: settings.edu.city === 'ilan',
  currentSelectedSchoolId: null
}

const mutations = {
  SET_IS_APP_VIEW: (state, isAPPView) => {
    state.isAPPView = isAPPView
  },
  SET_TAGS: (state, tags) => {
    state.tags = tags
  },
  SET_NAVS: (state, navs) => {
    state.navs = navs
  },
  SET_PAGEDATA: (state, data) => {
    state.pageData = data
  },
  SET_ORIGIN_TAG: (state, data) => {
    state.originTag = data
  },
  SET_IPC_STATUS: (state, status) => {
    state.ipcStatus = status
  },
  SET_OVERLAYLOADING: (state, status) => {
    state.overlayLoading = status
  },
  SET_CURRENT_SELECTED_SCHOOL_ID: (state, id) => {
    state.currentSelectedSchoolId = id
  }
}

const actions = {
  async getTags({ commit, state, rootGetters }) {
    commit('SET_TAGS', null)
    const flatItems = []
    const mapping = (item, level, parentId) => {
      const option = state.navList[level]
      const parents = [...parentId]
      if (level === 1) {
        // 區域層級
        item.cityId = parentId[0]
      }
      if (level === 2) {
        // 學校層級
        item.cityId = parentId[0]
        item.districtId = parentId[1]
      }
      if (level === 3) {
        // 教室層級
        item.cityId = parentId[0]
        item.districtId = parentId[1]
        item.schoolId = parentId[2]
      }
      let branch = []
      const roleLevel = state.roleLevel[rootGetters['user/userRole'].role]
      if (item.branch && item.branch.length > 0 && roleLevel !== 1) {
        branch = item.branch.map((d) =>
          mapping(d, level + 1, [...parents, item.id])
        )
      }
      const mapped = {
        ...item,
        icon: option.icon,
        level,
        branch,
        displayName: item.cname || item.name,
        parents,
        to:
          level >= roleLevel || [0, 1].includes(level)
            ? `${option.url}/${item.id}`
            : ''
      }
      flatItems.push(mapped)
      return mapped
    }
    try {
      const { data } = await Tag.search('')
      commit('SET_ORIGIN_TAG', data)
      data.to = '/city/' + data.id
      const branch = data.branch.map((d) => mapping(d, 1, [data.id]))
      const cityItem = {
        ...data,
        icon: data.icon,
        level: 0,
        branch: data.branch,
        displayName: data.cname || data.name,
        to: '/city/' + data.id
      }
      flatItems.push(cityItem)
      const tags = {
        ...data,
        branch
      }
      commit('SET_TAGS', tags)
      commit('SET_NAVS', flatItems)
    } catch (error) {
      console.error(error)
    }
  },
  setNavs({ commit }, data) {
    commit('SET_NAVS', data)
  },
  setPage({ commit }, data) {
    commit('SET_PAGEDATA', data)
  },
  getPage({ commit, state }, id) {
    if (!state.navs) return
    const item = state.navs.find((element) => element.id === id) || null
    commit('SET_PAGEDATA', item)
  },
  checkAPPView({ commit }) {
    const isAPPView = JSON.parse(localStorage.getItem('isAPPView') || false)
    commit('SET_IS_APP_VIEW', isAPPView)
  },
  setisAPPView({ commit }, isAPPView) {
    localStorage.setItem('isAPPView', isAPPView)
    commit('SET_IS_APP_VIEW', isAPPView)
  },
  async getIPCStatus({ commit, state, dispatch }) {
    console.log('get IPC status')
    try {
      let schoolId = JSON.parse(localStorage.getItem('user')).school_id
      const { data } = await IPC.getSchoolIPC(schoolId)
      // let responseIpcStatus = Math.round(Math.random())
      let responseIpcStatus = data.isConnected
      if (responseIpcStatus !== state.ipcStatus) {
        commit('SET_IPC_STATUS', responseIpcStatus)
        if (responseIpcStatus && window.xmppClient.status !== 'online')
          dispatch('user/getInfo', null, { root: true })
        else window.xmppClient.stop()
      }
      let currentRouteName = router.currentRoute.name
      if (responseIpcStatus && currentRouteName === 'IpcDisconnected')
        router.push({ name: 'Home' })
      else if (!responseIpcStatus && currentRouteName !== 'IpcDisconnected')
        router.push({ name: 'IpcDisconnected' })
    } catch (error) {
      console.error(error)
    }
  },
  setOverlayLoading({ commit }, data = false) {
    commit('SET_OVERLAYLOADING', data)
  },
  setCurrentSelectedSchoolId({ commit }, id) {
    commit('SET_CURRENT_SELECTED_SCHOOL_ID', id)
  }
}

const getters = {
  tags: (state) => state.tags,
  navs: (state) => state.navs,
  schools: (state) => {
    const schools = []
    if (!state.originTag || !state.originTag.branch) {
      return schools
    }
    state.originTag.branch.forEach((dist) => {
      dist.branch.forEach((school) => {
        delete school.branch
        school.distId = dist.id
        schools.push(school)
      })
    })
    return schools
  },
  pageData: (state) => state.pageData,
  isAPPView: (state) => state.isAPPView,
  ipcStatus: (state) => state.ipcStatus,
  overlayLoading: (state) => state.overlayLoading,
  isIlan: (state) => state.isIlan,
  currentSelectedSchoolId: (state) => state.currentSelectedSchoolId
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
